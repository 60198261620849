<!--company details-->
<template>
    <div class="job_details">
        <div class="container">
            <div class="row">
                <div class="col-md-8 company_details">
                    <!--left-->
                    <div class="left">
                        <div id=" head">
                            <header>
                                <div class="img_logo_1 ">
                                    <img style="width: 13%; margin: 5px 10px 10px 5px" v-if="details.logo != ''" :src="IMAGE_URL + details.logo" alt="HTML5 Icon">
                                    <img style="width: 13%; margin: 5px 10px 10px 5px" src="../../assets/no-image.png" alt="" v-else>
                                    <span style="font-weight: bold; color: #2C3B63" class="company_name1" v-html="details.brigad_ner"></span>
<!--                                    <Icon style="color: #EFBC11; float: right" class="detailsIcon" type="ios-arrow-forward"/>-->
                                </div>
                            </header>
                        </div>
                        <div class="ROW">
                            <div class="div_job ">
                                <span class="job_name">{{ details.mergejil }}</span>
                            </div>
                            <!-- ГҮЙЦЭТГЭХ ҮНДСЭН ҮҮРЭГ-->
                            <div class="list_1">
                                <p class="contact">{{ 'guitsetgeh_undsen_uureg' | translate }}</p>
                                <ul class="list_ul" v-html="details.uureg"></ul>
                            </div>
                            <!-- ТАВИГДАХ ШААРДЛАГА-->
                            <div class="list_1">
                                <p class="contact">{{ 'tavigdah_shaardlaga' | translate }}</p>
                                <ul class="list_ul" v-html="details.shaardlaga"></ul>
                            </div>
                            <!-- НЭМЭЛТ МЭДЭЭЛЭЛ-->
                            <div class="list_1">
                                <p class="contact">{{ 'nemelt_medeelel' | translate }}</p>
                                <ul class="list_ul" v-html="details.nemelt_medeelel"></ul>
                            </div>
                            <!--БУСАД МЭДЭЭЛЭЛ-->
                            <div class="list_1">
                                <p class="contact">{{ 'busad_medeelel' | translate }}</p>
                                <div class="other_information ">
                                    <table>
                                        <tr>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'ajliin_turul' | translate }}</p>
                                                    <b>{{ details.ajiliin_torol }}</b>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'tsalin_hulsnii_turul' | translate }}</p>
                                                    <b>{{ details.tsalin_torol }}</b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'tsagiin_turul' | translate }}</p>
                                                    <b>{{ details.tsagiin_torol  }}</b>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'aimag_niislel' | translate }}</p>
                                                    <b>{{ details.aimagname }}</b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'sum_duureg' | translate }}</p>
                                                    <b>{{ details.sumname }}</b>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'bag_horoo' | translate }}</p>
                                                    <b>{{ details.bagname }}</b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="tr_different_border">
                                            <td>
                                                <div class="padd1 ">
                                                    <p>{{ 'zarlasan_ognoo' | translate }}</p>
                                                    <b>{{ details.e_ognoo | date}}</b>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="padd1 ">
                                                    <p> {{ 'anket_avah_ognoo' | translate }}</p>
                                                    <b class="clr">{{details.d_ognoo | date}}</b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="userToken != null && userToken != false && user">
                                            <td colspan="2" style="border: none">
                                                <AnketIlgeeh :zar_id="this.id" type="brigad" :brid_id="details.brigad_id"/>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <!--холбоо барих-->
                            <div class="list_1">
                                <p class="contact">{{ 'holboo_barih' | translate }}</p>
                                <div class="row">
                                    <div class="col-sm-8 company_socials">
                                        <div class="row row_1">
                                            <a :href="details.face" target="_blank">
                                                <i class="ivu-icon ivu-icon-logo-facebook"></i>
                                            </a>
                                            <a :href="details.twitter" target="_blank">
                                                <i class="ivu-icon ivu-icon-logo-twitter"></i>
                                            </a>
                                            <a :href="details.linked_in" target="_blank">
                                                <i class="ivu-icon ivu-icon-logo-linkedin"></i>
                                            </a>
                                        </div>
                                        <div class="row row_2">
                                            <div class="col-sm-1">
                                                <Icon type="md-map"/>
                                            </div>
                                            <div class="col-sm-10">
                                                <span class="address">Монгол улс, Улаанбаатар хот, {{details.sumname}}, {{details.bagname}} {{details.gudam}}, {{details.toot}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 address">
                                        <p>
                                            <Icon type="md-call"/> &nbsp; {{details.utas_one}}
                                        </p>
                                        <p>
                                            <Icon type="md-call"/> &nbsp; {{details.utas_two}}
                                        </p>
                                        <p>
                                            <Icon type="md-mail"/> &nbsp; {{details.mail}}
                                        </p>
                                        <p>
                                            <Icon type="md-globe"/> &nbsp; {{details.web}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--ТУС АЖЛЫН ТӨРЛИЙН ИЖИЛ ЗАРУУД-->
                <div class="col-md-4 job_posting_details">
                    <!--right-->
                    <div class="right">
                        <div class="row_2">
                          <div class="title_1">
                            {{ 'zarlagdsan_ijil_zaruud' | translate }}
                            <svg class="svg_text_line">
                              <line x1="80" style="stroke:#EFBC11; stroke-width:3"/>
                            </svg>
                          </div>
                            <div class="p_table">

                                <table width="100%">
                                    <tbody>
                                    <!--tr-->
                                    <tr v-for="(other, index) in others" :key="index">
                                        <td>
                                            <div class="col_1 ">
                                                <router-link :to="`/br_a_b_zar_details/${other.id}`">
                                                    <p>{{ other.ajiliin_torol}}</p>
                                                </router-link>
                                                <div class="sub_col ">
                                                    <router-link :to="`/br_a_b_zar_details/${other.id}`">
                                                        <div class="c_name">{{other.brigad_ner}}</div>
                                                    </router-link>
                                                    <div class="text_bold">{{ 'where' | translate }}: <span
                                                            class="not_bold"> {{other.aimagname}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="td_2_1">
                                                <p>{{ 'anket_avah_ognoo' | translate }}</p>
                                                <p class="p_text">{{other.d_ognoo | date}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {
    GET_BRGD_AJLIIN_BAIR_ZAR_VIEW,
    GET_BRGD_AJLIIN_BAIR_ZAR_VIEW_DETAILS,
    IMAGE_URL
} from "../../graphql/queries";
import AnketIlgeeh from "../../components/anketIlgeeh"
import {mapGetters} from "vuex";

export default {
    name: "brigad_ajiliin_bairnii_zar_details",
    props: ['id'],

    components:{
      AnketIlgeeh,

    },
    data() {
        return {
            details: {},
            loading: true,
            IMAGE_URL: IMAGE_URL,
            others:[],
        }
    },
  computed: {
    ...mapGetters([
      'user',
      'userToken',
    ])
  },
    methods: {

        getDetails() {
            console.log(this.id);
            this.loading = true;
            this.$apollo.query({
                query: GET_BRGD_AJLIIN_BAIR_ZAR_VIEW_DETAILS,
                variables: {z_id: this.id.toString(),}
            }).then(({data}) => {
                this.details = data.ds_brigad_ajilin_bair_zar_view[0];
                this.loading = false;
            })
        },
        getDetailsOtherPostings() {
            this.loading = true;
            this.$apollo.query({
                query: GET_BRGD_AJLIIN_BAIR_ZAR_VIEW,
            }).then(({data}) => {
                this.others = data.ds_brigad_ajilin_bair_zar_view;
                this.loading = false;
            })
        },
    },
    mounted() {
        this.getDetails();
        this.getDetailsOtherPostings();
    },

}
</script>

<style scoped>

</style>


